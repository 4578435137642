<template>
    <q-layout view="hHh lpR fFf" class="main_wrap">
        <q-page-container class="page_wrap">
            <head_block @scrollToLink="scrollToLink"/>
            <slider_block/>
            <body_block @setScrollElem="setScrollElem"/>
            <footer_block/>
        </q-page-container>
    </q-layout>
</template>

<script setup>
    import head_block from "./components/head_block";
    import slider_block from "./components/slider_block";
    import body_block from "./components/body_block";
    import footer_block from "./components/footer_block";

    import { scroll } from 'quasar';
    import { ref } from 'vue';
    
    const link = ref(null);
    const { getScrollTarget, setVerticalScrollPosition } = scroll

    const setScrollElem = (el) => {
        link.value = el;
    }

    const scrollToLink = () => {
        const target = getScrollTarget(link.value);
        const offset = link.value.offsetTop - 120;
        const duration = 300;
        setVerticalScrollPosition(target, offset, duration);
    }

</script>

<style>
.main_wrap{
    display: flex;
    justify-content: center;
}
.page_wrap{
    max-width: 410px;
}
</style>