<template>
    <div class="col-12 q-px-xl">
        <ol>
            <li>
                <p>В нижнем меню нажмите на иконку «Поделиться»</p>
                <q-img class="step-img" src="@/assets/install/ios/step_1.png"/>
            </li>
            <li>
                <p>Затем выберите «Добавить на экран Домой»</p>
                <q-img class="step-img" src="@/assets/install/ios/step_2.png"/>
            </li>
        </ol>
    </div>
</template>

<style scoped>
    @import '@/styles/install.css';
</style>