<template>
    <div class="col-12 q-px-xl">
        <ol>
            <li>
                <p>Откройте меню браузера, нажав три точки в правом верхнем углу</p>
                <q-img class="step-img" src="@/assets/install/android/step_1.png"/>
            </li>
            <li>
                <p>Затем выберите «Установить приложение»</p>
                <q-img class="step-img" src="@/assets/install/android/step_2.png"/>
            </li>
            <li>
                <p>Нажмите «Установить». После установки иконка появится на экране смартфона</p>
                <q-img class="step-img" src="@/assets/install/android/step_3.png"/>
            </li>
        </ol>
    </div>
</template>

<style scoped>
    @import '@/styles/install.css';
</style>