<template>
    <div class="row body-wrap">
        <div class="col-12 q-py-xl column items-center">
            <p class="title">Как установить <br> приложение</p>
            <p class="text-center">Перейдите на сайт по ссылке:</p>
            <a 
                ref="link" 
                class="link" 
                href="https://portal.hpdd.ru/pwapp" 
                target="_blank" 
                rel="noopener noreferrer"
            >
                https://portal.hpdd.ru/pwapp
            </a>
            <p class="text-center">и затем следуйте инструкции ниже</p>
            <q-icon name="expand_more" size="32px"/>
            <div class="row full-width justify-center">
                <q-btn 
                    class="q-mx-sm" 
                    :class="iosBtnClass"
                    rounded 
                    unelevated 
                    label="IOS"
                    @click="setActiveBtn('ios')"
                />
                <q-btn 
                    class="q-mx-sm"
                    :class="androidBtnClass"
                    rounded 
                    unelevated 
                    label="Android"
                    @click="setActiveBtn('android')"
                />
            </div>
        </div>
        <install_ios v-if="activeBtn === 'ios'"/>
        <install_android v-else-if="activeBtn === 'android'"/>
    </div>
</template>

<script setup>
    import { ref, computed, onMounted } from 'vue';
    import install_ios from './install/ios'
    import install_android from './install/android'

    const activeBtn = ref('ios');
    const link = ref(null);
    const emit = defineEmits(['setScrollElem']);

    const setActiveBtn = (label)=>{
        activeBtn.value = label;
    }
    const iosBtnClass = computed(()=>{
        return activeBtn.value === 'ios' ? 'active' : 'unactive';
    })
    const androidBtnClass = computed(()=>{
        return activeBtn.value === 'android' ? 'active' : 'unactive';
    })

    onMounted(() => {
        if(link.value) 
            emit('setScrollElem', link.value);
    })
</script>

<style scoped>
    .body-wrap{
        background: #F5F5F5;
    }
    .title{
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
    }
    .link{
        color: #2FC7F7;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 16px;
    }
    .active{
        background-color: #2FC7F7 !important;
        width: 100px !important;
    }
    .unactive{
        background-color: #bdbdbd !important;
        width: 100px !important;
    }
</style>