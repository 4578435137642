<template>
    <div class="row q-py-lg">
        <div class="col-12 column justify-center">
            <p class="title">Ознакомьтесь <br> с основными функциями </p>
        </div>
        <div class="col-12">
            <q-virtual-scroll 
                :items="palets" 
                virtual-scroll-horizontal
                v-slot="{item, index}"
            >
                <div :key="index" class="palete-wrap" :style="index === 1 ? 'margin-left: -120px; margin-right: -120px;' : ''">
                    <q-img :src="item.img"/>
                    <div class="base-badge" :style="item.style">
                        <p class="badge-text">{{ item.text }}</p>
                    </div>
                </div>
            </q-virtual-scroll>
        </div>
    </div>
</template>

<script setup>
    const palets = [
        {
            img: require('@/assets/slider_block/phone1.png'),
            text: 'Новости, лайки и комментарии для обратной связи',
            style: 'width: 143px; top: 25%; left: 10%;'
        },
        {
            img: require('@/assets/slider_block/phone2.png'),
            text: 'Благодарите коллег и получайте благодарности',
            style: 'width: 143px; top: 55%; right: 10%;'
        },
        {
            img: require('@/assets/slider_block/phone3.png'),
            text: 'Зарабатывайте звёзды, проверяйте свой баланс, покупайте в звёздном магазине',
            style: 'width: 166px; top: 40%; right: 10%;'
        }
    ];
</script>

<style scoped>
.title{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-bottom: -20px;
    padding-top: 20px;
}
.base-badge{
    position: absolute;
    border-radius: 25px;
    background: linear-gradient(134deg, #E1DC58 12.57%, #17B5E8 88.78%);
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.07);
    color: white;
    padding: 20px;
}
.badge-text{
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0;
    margin: 0;
}
.palete-wrap{
    width: 420px; 
    position: relative;
}
</style>