<template>
    <div class="row footer-wrap">
        <div class="col-12">
            <p class="text-h6 q-mb-none">Не забудьте сменить пароль <br> после авторизации!</p>
        </div>
    </div>
</template>

<style scoped>
    .footer-wrap{
        background-color: #2FC7F7;
        color: white;
        text-align: center;
        padding: 60px 0px 60px 0px;
    }
</style>