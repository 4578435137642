<template>
	<div class="row main-wrap">
		<div class="col-8 justify-center column items-center">
			<q-img src="@/assets/head_block/logo.png" height="69px" fit="contain"/>
			<p class="caption">Мы собрали все основные функции для взаимодействия с корпоративными направлениями</p>
			<q-btn flat rounded no-caps class="btn-clr" label="Установить" @click="scrollToLink"/>
		</div>
		<div class="col-4">
			<q-img class="phone-img" src="@/assets/head_block/phone.png" fit="contain"/>
		</div>
	</div>
</template>

<script setup>
	const emit = defineEmits(['scrollToLink']);

	const scrollToLink = () => {
		emit('scrollToLink');
	}
</script>

<style scoped>
.main-wrap{
	background-color: #2FC7F7;
	position: relative;
	overflow: hidden;
	height: 355px;
}
.btn-clr{
	background: #0067A6; 
	color: white;
	width: fit-content;
    align-self: center;
}
.caption{
	font-size: 13px;
    color: white;
    text-align: center;
    padding-top: 42px;
    padding-bottom: 42px;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	width: 70%;
}
.phone-img{
	position: absolute;
	top: 0;
	right: 0;
	width: 270px;
}
@media screen and (max-width: 380px) {
	.phone-img {
    	width: 240px;
  	}
}
@media screen and (max-width: 330px) {
	.phone-img {
    	width: 200px;
  	}
}
</style>